<template>
  <div>
    <logs-table />
  </div>
</template>

<script>
export default {
  components: {
    "page-header": () => import("../components/PageHeader.vue"),
    "logs-table": () => import("../components/logs/LogsTable.vue"),
  },
};
</script>