<template>
  <div>
    <t2t-sync-logs-table v-if=isCommercialVersion />
  </div>
</template>

<script>
import {Constants} from "@/constants/Constants";

export default {
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,
    };
  },
  components: {
    "page-header": () => import("../components/PageHeader.vue"),
  },
};
</script>