<template>
  <div>
    <settings />
  </div>
</template>

<script>
export default {
  components: {
    settings: () => import("../components/profile/settings/Settings.vue"),
  },
};
</script>
